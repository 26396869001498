import React from "react";
import abimg1 from "../../../../images/GreenGardan/01.jpg";
import abimg2 from "../../../../images/GreenGardan/02.jpg";
import nocImg from "../../../../images/NOC.png";
import alsaddatlogo from "../../../../images/partner/al-sadad.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { FaCheck } from "react-icons/fa6";
const GreenGardan = () => {
  return (
    <>
      <section className="apartment-section s2 ">
        <div className="container">
          <div className="row">
            <div className="section-title mb-10">
              <h2 className="text-center mb-50">
                Airport Green
                <span className="thin"> Gardens</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <div className="wp-content about-content mb-10 ml-50 rmb-50">
                <div className="section-title ">
                  <h6>BEST ARCHITECT</h6>

                  {/* <h6>5 marla size, flexible payment plan,</h6> */}
                  <ul className="styled-list">
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 marla size
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      3 Years flexible payment plan
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      NOC approved by RDA
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minutes drive from Motorway M1/M2
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minutes drive from Islamabad Airport
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      7 minutes drive from Islamabad Highway
                    </li>
                    <li className="styled-list-item">
                      <FontAwesomeIcon
                        style={{ marginRight: "10px" }}
                        icon={faCheckCircle}
                      />{" "}
                      5 minutes drive from GT Road
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="wp-images rmb-150">
                {/* <div className="experience-year">
                  <span className="number">
                    <FaCheck size={60} />
                  </span>
                  <h4>Modern architecture</h4>
                </div> */}
                <img src={abimg1} alt="Wordpress" />
                <img
                  style={{
                    width: "180px",
                  }}
                  src={nocImg}
                  alt="Wordpress"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GreenGardan;
