import React from "react";
import SilverVillas from "./SilverVillas";
import GoldenVillas from "./GoldenVillas";
// import DaimendVillas from "./DaimendVillas";

const Table = () => {
  return (
    <>
      <SilverVillas />
      <GoldenVillas />
      {/* <DaimendVillas /> */}
    </>
  );
};

export default Table;
