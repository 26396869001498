import React from "react";
import Bg from "../../images/wp/bg.jpg";
import abimg1 from "../../images/Home-image1.jpg";
import abimg2 from "../../images/Home-image1.jpg";
import abimg3 from "../../images/wp/wp-3.png";

const About = (props) => {
  return (
    <section
      id="about"
      className="wp-section pt-150 rmb-100"
      // style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center mb-75">
          <div className="section-title text-center">
            <h2>
              About <span className="thin">Us</span>
            </h2>
          </div>
          <p className="text-center col-lg-11 col-sm-12">
            {" "}
            Victory Villas is a Residential project by Maidaan being developed
            by Victory Construction which offers a range of Villas tailored to
            Construction material, client preferences, and financial
            circumstances. With fully furnished model houses ready for viewing,
            we invite clients to experience the quality firsthand. Our aim is to
            streamline the villa construction process, offering turnkey
            solutions that alleviate all worries. With Victory Villas, your
            dream of owning a luxurious home in Pakistan isn't just a vision.
            It's a reality going to happen. Currently, we are offering a diverse
            type of Villas including Silver and Golden villas that too
            with flexible payment options located within Airport Green Gardens
            near Islamabad International Airport. With its strategic location
            and thoughtful design, Victory Villas provides an ideal opportunity
            for Home owners especially overseas Pakistanis seeking quality and
            convenience throughout Pakistan.
          </p>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="wp-images rmb-150">
              <div className="experience-year">
                <span className="number">25</span>
                <h4>Years of Experience</h4>
              </div>

              <img src={abimg2} alt="victory villas" />
              <img className="d-none" src={abimg1} alt="victory villas" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="wp-content ml-50 rml-0 rpt-30">
              <div className="section-title mb-10">
                {/* <h6>ABOUT US</h6> */}
                <h2>
                  A Legacy of
                  <br />
                  <span className="thin">Excellence </span>
                </h2>
                <h6>Crafting Exceptional Living Experiences</h6>
              </div>
              <img src={abimg3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
