// src/components/footer/Footer.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import pImg1 from "../../images/ft1.jpg";
import pImg2 from "../../images/ft2.jpg";
import pImg3 from "../../images/ft3.jpg";
// import pImg4 from "../../images/victoryvillas4.jpg";
// import pImg5 from "../../images/victoryvillas5.jpg";
// import pImg6 from "../../images/victoryvillas6.jpg";
import CtaSection from "../CtaSection/CtaSection";
import "yet-another-react-lightbox/plugins/captions.css";

// const images = [pImg1, pImg2, pImg3, pImg4, pImg5, pImg6];
const images = [
  { src: pImg1, description: "Roman classic" },
  { src: pImg2, description: "classic" },
  { src: pImg3, description: "Modern contemporary" },
  // { src: pImg4, description: "Classic" },
  // { src: pImg5, description: "Modern" },
  // { src: pImg6, description: "Mediterranean" },
];

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [openIndex, setOpenIndex] = useState(null);

  const openLightbox = (index) => {
    setOpenIndex(index);
  };

  const closeLightbox = () => {
    setOpenIndex(null);
  };

  return (
    <div>
      <CtaSection />
      <footer className="footer-section pt-220">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-8">
              <div className="instagram-posts">
                <div className="instagram-item">
                  <img
                    src={images[0].src}
                    alt={images[0].description}
                    onClick={() => openLightbox(0)}
                  />
                  <div className="instagram-overlay">
                    <span onClick={() => openLightbox(0)}>
                      <i className="fa fa-picture-o"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-4">
              <div className="contact-widget">
                <h3>Get in touch for a deal today.</h3>
                <h6>
                  To accomplish luxury, functionality, and urbanized elegance,
                  contact us at (+92) 304-111-3309
                </h6>
                <h6>
                  Airport Green Gardens, near Islamabad International Airport,
                  Islamabad.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-55">
          <div className="container">
            <div className="bottom-inner">
              <div className="social-icons">
                <a
                  href="https://twitter.com/JhelumVillas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-twitter"></i>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100090550677345"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
                <a
                  href="https://www.instagram.com/victory.villas?igsh=czVybjl4b21ibTg1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
              <div className="copyright">
                <p>
                  © Copyright 2024 by{" "}
                  <Link onClick={ClickHandler} to="/">
                    Victory Villas
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Lightbox
          open={openIndex !== null}
          close={closeLightbox}
          slides={images}
          plugins={[Captions, Zoom]}
          currentIndex={openIndex}
        />
      </footer>
    </div>
  );
};

export default Footer;
