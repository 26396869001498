import React from "react";

const Heading = (props) => {
  return (
    <div className="container">
      <div className="section-title mb-50">
        <h2>
          Ongoing <span className="thin">Projects:</span>
        </h2>
        <h3>An Immediate Deal:</h3>
      </div>
      <div className="apartment-tab"></div>
    </div>
  );
};

export default Heading;
