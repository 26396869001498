import React from "react";

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#E2BE5D',

};
const GoldenVillas = () => {
  return (
    <div
      style={{
        backgroundColor: "#f8f9fa",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Golden Villas</h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4">
        (Total covered area: 2182 sqft)
        </h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm" style={tableStyle}>
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">21,820,000 PKR  </td>
                <td>2,182,000</td>
                <td>3,273,000</td>
                <td>2,182,000</td>
                <td>3,273,000</td>
                <td>198,364</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


    </div>
  );
};

export default GoldenVillas;
