import React, { useState } from "react";
import fImg from "../../images/faq/faq-right-bg.png";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { Link } from "react-router-dom";

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq-section">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-6 pr-125 rpr-15">
            <div className="faq-left-image"></div>
          </div>
          <div className="col-lg-6 pl-0">
            <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
              <div className="section-title mb-35">
                <h6>FAQ</h6>
                <h2>
                  Frequently Asked
                  <span className="thin"> Questions</span>
                </h2>
              </div>
              <div id="accordion">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="1" className="card-header">
                      What kind of Villas are available in Victory Villas?
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      The following types of Villas are available:
                      <br />
                      Silver Villas
                      <br />
                      Golden Villas
                  
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="2" className="card-header">
                      What are the Victory Villa sizes?
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      Victory Villa is offering 5 Marla Villas of different
                      categories further explained with constructional area as
                      mentioned below:
                      <br />
                      Silver Villas (1315 sqft)
                      <br />
                      Golden Villas (2182 sqft)
                    
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="3" className="card-header">
                      What is the difference between Silver and Golden Villas?
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      Here are the details:
                      <br /> <b>Silver Villa:</b>
                      <br /> Classic single story,single unit design.
                      <br /> Spacious layouts that seamlessly blend
                      sophistication with comfort in an economical range.
                      <br /> Total Construction Area: 1315 sqft
                     
                      <br /> <b>Golden Villas</b>
                      <br /> Luxurious double story and double unit Living
                      Represent luxury
                      <br /> combining timeless elegance with contemporary
                      comfort
                      <br /> Total Construction Area: 2182 sqft
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      Who is the project owner?
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      Maidaan Group of Companies is the project head of Victory
                      Villas
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      Who is the developer?
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      Development works being done by a very renowned
                      construction company named Victory Constructions with 42+
                      years of experience in the field. Visit website:
                      <Link to={"https://thevictoryconstruction.com/ "}>
                        https://thevictoryconstruction.com/{" "}
                      </Link>
                      for details regarding their already done projects.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            <div className="faq-right-bg">
              <img src={fImg} alt="FAQ" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
