import React from "react";

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#E2BE5D',

};
const GoldenVillas = () => {
  return (
    <div
      style={{
        backgroundColor: "#f8f9fa",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Golden Villas</h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4">
        (Total covered area: 2182 sqft)
        </h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm" style={tableStyle}>
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">21,820,000 PKR  </td>
                <td>2,182,000</td>
                <td>3,273,000</td>
                <td>2,182,000</td>
                <td>3,273,000</td>
                <td>198,364</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 1 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2320 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 4</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: Yes</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">23,200,000 PKR </td>
                <td>2,320,000</td>
                <td>3,480,000</td>
                <td>2,320,000</td>
                <td>3,480,000</td>
                <td>210,909</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 2 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2145 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 3</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: No</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">21,450,000 PKR  </td>
                <td>2,145,000</td>
                <td>3,217,500</td>
                <td>2,145,000</td>
                <td>3,217,500</td>
                <td>195,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 3 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2293 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 4</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: Yes</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">22,930,000 PKR</td>
                <td>2,293,000</td>
                <td>3,439,500</td>
                <td>2,293,000</td>
                <td>3,439,500</td>
                <td>208,455</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 4 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2182 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 4</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: No</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">21,820,000 PKR </td>
                <td>2,182,000</td>
                <td>3,273,000</td>
                <td>3,273,000</td>
                <td>3,273,000</td>
                <td>198,364</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 5 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2293 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 2+3</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: Yes</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">22,930,000 PKR  </td>
                <td>2,293,000</td>
                <td>3,439,500</td>
                <td>2,293,000</td>
                <td>3,439,500</td>
                <td>208,455</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 6 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2097 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 3</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: No</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">25,164,000 PKR  </td>
                <td>2,516,400</td>
                <td>3,774,600</td>
                <td>2,516,400</td>
                <td>3,774,600</td>
                <td>228,764</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 7 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 2364 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 4</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: Yes</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">28,368,000 PKR </td>
                <td>2,836,800</td>
                <td>4,255,200</td>
                <td>2,836,800</td>
                <td>4,255,200</td>
                <td>257,891</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 8 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 1430 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 2</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: No</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">14,300,000 PKR </td>
                <td>1,430,000</td>
                <td>2,145,000</td>
                <td>1,430,000</td>
                <td>2,145,000</td>
                <td>130,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <div className="container">
        <div className=" mt-5 d-flex align-items-center justify-content-center">
          <h3 className="text-center">Layout 9 </h3>
          <p className="ms-2 pt-15"> (10,000 pkr/ sqft)</p>
        </div>
        <h6 className="text-center mb-4"> (Total covered area: 1315 sqft)</h6>
          <h6 className="text-center mb-4"> No of Beds: 2</h6>
          <h6 className="text-center mb-4"> Bed on Mumty: No</h6>

        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            {" "}
            <thead>
              <tr>
                <th scope="col">Total Price</th>
                <th scope="col">Down Payment 10%</th>
                <th scope="col">Confirmation 15%</th>
                <th scope="col">Yearly Installment 30%</th>
                <th scope="col">Possession 15%</th>
                <th scope="col">33 Monthly installment 30%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td scope="row">13,150,000 PKR </td>
                <td>1,315,000</td>
                <td>1,972,500</td>
                <td>1,315,000</td>
                <td>1,972,500</td>
                <td>119,545</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>



    </div>
  );
};

export default GoldenVillas;
